@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @keyframes zoom {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
}

body {
  .content-wrapper {
    @apply prose-sm sm:prose md:prose-lg font-normal;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-balance !mb-6 font-secondary !font-normal before:absolute;
}

.content-wrapper {
  @apply !max-w-[calc(100%_-_2rem)] sm:!max-w-[calc(100%_-_12rem)] 2xl:!max-w-screen-2xl 2xl:px-8 mx-auto py-8 sm:py-16;

  p {
    @apply my-0;

    & + p {
      @apply mt-4 mb-0;
    }
  }
  h1,
  h2 {
    &:before {
      @apply w-16 h-2 bg-primary-500 -translate-y-4;
    }
  }
  h3,
  h4 {
    @apply !mt-8 !mb-4;
    &:before {
      @apply w-10 h-1 bg-primary-500 -translate-y-4;
    }
  }

  .sep {
    @apply border-t-[1px] border-slate-300 my-4 before:absolute relative first-of-type:border-0;
    &:before {
      @apply w-full bg-white translate-y-[1px] h-[1px] top-0 left-0 first-of-type:hidden;
    }
  }

  ul {
    @apply !list-none;
    li {
      @apply before:absolute relative;

      &:before {
        @apply size-3 bg-primary-500 rounded-full top-0 left-0 -translate-x-4 translate-y-[10px];
      }
    }

    ul {
      li {
        &:before {
          @apply size-2 bg-primary-300 rounded-full top-0 left-0 -translate-x-3 translate-y-[12px];
        }
      }
    }
  }

  *::marker {
    @apply !text-primary-500 !size-5 !list-none;
  }
}

.background-dark,
.background-light {
  @apply my-16;

  .over-top {
    @apply -mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20 translate-y-px;
  }
  .over-bottom {
    @apply -mb-20 top-auto bottom-0 left-0 right-0 w-full absolute scale-x-flip scale-y-flip h-20 -translate-y-px;
  }
}
.background-dark {
  .content-wrapper {
    @apply *:text-white;

    h1,
    h2 {
      &:before {
        @apply bg-white;
      }
    }
  }
}

.garanzia {
  @apply grid lg:grid-cols-2 gap-8 my-8;

  .passo {
    @apply p-4 sm:p-8 rounded-lg bg-gray-100;
  }
}

.cta {
  @apply inline-block rounded-full leading-snug py-2 px-3 bg-primary-500 !text-white !font-semibold text-center transition-all border-2 border-white hover:border-primary-500 hover:bg-white hover:!text-primary-500 text-sm md:text-base !no-underline sm:mx-2 whitespace-nowrap;

  /* Add auto zoom animation */
  animation: zoom 3s infinite ease-in-out;
}
.img-rounded {
  @apply rounded-2xl shadow-white border-[0rem] border-white max-h-[90vh] object-cover aspect-auto shadow-[10px_10px_0px_0px_rgba(255,255,255,1)];
}
.lead {
  @apply border-2 border-primary-500 p-4 max-w-screen-md mx-auto bg-primary-500 !text-white !my-8;
}

/** FAQ */
.faq {
  .faq-titolo {
    @apply text-primary-500 relative font-semibold flex justify-between md:text-lg my-4 p-4 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 transition-all text-sm sm:text-base;

    div {
      @apply max-w-[95%];
    }
  }
  .faq-content {
    @apply px-8 py-4;
  }

  strong {
    @apply text-black;
  }
}

.chevron {
  @apply mt-2 flex-shrink-0;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}
.chevron.up {
  transform: rotate(-135deg);
}

/** GALLERY */
.gallery-wrapper {
  @apply py-8 sm:py-16;

  .gallery {
    @apply flex gap-8 py-4 overflow-auto max-w-full px-8 pb-8 snap-x;

    figure {
      @apply relative shrink-0 xl:shrink max-w-[calc(100%_-_4rem)] mx-auto snap-center;

      figcaption {
        @apply text-center mt-2 text-[12px] sm:text-sm bg-white p-2 absolute -bottom-2 -right-2 rounded-xl;
      }
    }
  }
}

.custom-scrollbar {
  ::-webkit-scrollbar {
    @apply bg-primary-300;
    height: 12px;
    width: 12px;
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: white;
  }
}

/** BACK TO TOP */
#back-to-top {
  @apply fixed bottom-5 right-5 z-50 size-10 cursor-pointer items-center rounded-full bg-black text-center text-white transition-all hover:scale-110 hover:bg-black;

  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
#back-to-top.show {
  opacity: 1;
  visibility: visible;
}

/** VENOBOX */
.vbox-close {
  @apply m-4;
}
